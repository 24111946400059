import React, { createContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import OpenAI from 'openai-api';

import formatResults from '../../utils/formatResults';
import { fetch_query } from '../../utils/apiServices';
import { SearchTemplate, ArticleTemplate } from '../../utils/templates';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const openai = new OpenAI(process.env.REACT_APP_OPENAI_API_KEY);

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const lastResultsString = useRef('');

  const selectLanguage = (lang) => {
    localStorage.setItem('language', lang);
    setLanguage(lang);
  };

  const generateArticleText = async (text) => {
    setIsLoadingArticle(true);

    try {
      const response = await fetch_query(searchTerm);
      let chunks = [response]; // Start with the first chunk

      while (true) {
        // Get the next chunk of the response
        const nextChunk = await fetch_query(searchTerm);

        if (!nextChunk) {
          // Append a new line for an empty chunk
          chunks.push('\n');
        } else {
          chunks.push(nextChunk);
        }

        // Update search results with the accumulated chunks
        setSearchResults((prevResults) => [...prevResults, ...chunks]);
      }

      setIsLoadingArticle(false);
      console.log('Stream Ended');
    } catch (error) {
      console.error('Fetch Error:', error);
      setIsLoadingArticle(false);
      alert('There was an error while fetching the data');
    }
  };


  const generateResults = async (searchTerm, onData) => {
  setIsLoadingResults(true);

  try {
    const cleanup = fetch_query(
      searchTerm,
      (chunk) => {
        // Handle each chunk of data
        onData(chunk);
      },
      (error) => {
        // Handle errors
        console.error('Error fetching data:', error);
      }
    );

    // Clean up the event source connection when the component unmounts or when generating new results
    return () => cleanup();
  } catch (error) {
    alert('There are problems accessing the API');
    window.location.href = '/';
  } finally {
    setIsLoadingResults(false);
  }
};



  const getResults = async (term) => {
    setSearchResults([]);

    generateResults(term, (chunk) => {
      setSearchResults((prevResults) => [...prevResults, chunk]);
    });
  };

  const getMoreResults = async (numberOfPrevResults) => {
    const onData = (chunk) => {
      setSearchResults((prevResults) => [...prevResults, chunk]);
    };

    generateResults(searchTerm, onData);
  };

  const gooptSearch = (term) => {
    if (term.trim().length > 0) {
      navigate('/results?search=' + term.trim());
      getResults(term.trim());
    }
  };

  const imFeelingLucky = async (term) => {
    if (term.length > 0) {
      navigate('/site?term=' + term.trim());
      setSearchResults([]); // Clear the existing search results

      try {
        const onData = (chunk) => {
          setSearchResults((prevResults) => prevResults + chunk);
        };

        await generateResults(term, onData);
      } catch (error) {
        alert('There are problems accessing the API');
        window.location.href = '/';
      }
    }
  };

  const getSearchTemplate = (lang = language) => {
    switch (lang) {
      case 'en':
        return SearchTemplate.en;

      case 'es':
        return SearchTemplate.es;

      case 'eo':
        return SearchTemplate.eo;

      default:
        return false;
    }
  };

  const getArticleTemplate = (lang = language) => {
    switch (lang) {
      case 'en':
        return ArticleTemplate.en;

      case 'es':
        return ArticleTemplate.es;

      case 'eo':
        return ArticleTemplate.eo;

      default:
        return false;
    }
  };

  return (
    <AppContext.Provider
      value={{
        language,
        selectLanguage,
        searchTerm,
        setSearchTerm,
        setIsLoadingArticle,
        searchResults,
        isLoadingResults,
        isLoadingArticle,
        generateArticleText,
        gooptSearch,
        getMoreResults,
        imFeelingLucky,
        getSearchTemplate,
        getArticleTemplate,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
