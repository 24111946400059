
const SearchTemplate = {
  en: "Respond in JSON as if it were the result of a Google search.\nThe results should be informative, relevant, interesting, with 150 letters.\n\"\"\"\nSearch: Chess\n\n{\"title\": \"What is Chess?\",\"text\": \"Chess is a board game for two opponents in which each player has 16 movable pieces that are placed on a board, divided into 64 squares or squares. In its competitive version, it is considered as a sport, although nowadays it clearly has a social, educational and therapeutic dimension ...\"}\n\n{\"title\": \"How to play Chess (rules)\",\"text\": \"The rules of chess are not as complicated as they seem. More and more educational centers are paying special attention to chess for children. A game that improves creativity, enhances memory, increases reading speed and helps to solve problems, among other benefits ...\"}\n\"\"\"\nSearch: ",
};

/*

const SearchTemplate = {
  en: "Respond in JSON as if it were the result of a Google search.\nThe results should be informative, relevant, interesting, with 150 to 400 letters.\n\"\"\"\nSearch: Chess\n\n{\"title\": \"What is Chess?\",\"text\": \"Chess is a board game for two opponents in which each player has 16 movable pieces that are placed on a board, divided into 64 squares or squares. In its competitive version, it is considered as a sport, although nowadays it clearly has a social, educational and therapeutic dimension ...\"}\n\n{\"title\": \"How to play Chess (rules)\",\"text\": \"The rules of chess are not as complicated as they seem. More and more educational centers are paying special attention to chess for children. A game that improves creativity, enhances memory, increases reading speed and helps to solve problems, among other benefits ...\"}\n\"\"\"\nSearch: ",
  es: "Responde en JSON como si fuera resultado de una búsqueda en Google.\nLos resultados deben ser informativos, relevantes, interesantes, con 150 a 400 letras.\n\"\"\"\nBuscar: Ajedrez\n\n{\"title\": \"¿Qué es el Ajedrez?\",\"text\": \"El ajedrez es un juego de tablero entre dos contrincantes en el que cada uno dispone al inicio de 16 piezas móviles que se colocan sobre un tablero,​ dividido en 64 casillas o escaques. En su versión de competición, está considerado como un deporte aunque en la actualidad tiene claramente una dimensión social,​ educativa​ y terapéutica ...\"}\n\n{\"title\": \"Cómo jugar al ajedrez (reglas del ajedrez)\",\"text\": \"Las reglas del ajedrez no son tan complicadas como parece. Cada vez son más los centros educativos que prestan especial atención al ajedrez para niños. Un juego que mejora la creatividad, potencia la memoria, incrementa la velocidad lectora y ayuda a resolver problemas, entre otros beneficios ...\"}\n\"\"\"\nBuscar: ",
  eo: "Respondu per JSON kvazaŭ ĝi estus la rezulto de serĉo en Google.\nLa rezultoj estu informaj, trafaj, interesaj, kun 150 ĝis 400 literoj.\n\"\"\"\nSerĉu: Ŝako\n\n{\"title\": \"Kio estas Ŝako?\",\"text\": \"Ŝako estas tabulludo inter du kontraŭuloj, en kiu ĉiu havas komence 16 movantajn pecojn, kiuj estas metitaj sur tabulon, dividitaj en 64 kvadratojn aŭ kvadratojn. En sia konkurenciva versio, ĝi estas konsiderata sporto kvankam nuntempe ĝi klare havas socian, edukan kaj terapian dimension ...\"}\n\n{\"title\": \"Kiel ludi ŝakon (reguloj)\",\"text\": \"La reguloj de ŝako ne estas tiel komplikaj kiel ŝajnas. Pli kaj pli multaj edukaj centroj atentas ŝakon por infanoj. Ludo, kiu plibonigas la kreemon, plibonigas memoron, pliigas legan rapidon kaj helpas solvi problemojn, inter aliaj avantaĝoj ...\"}\n\"\"\"\nSerĉi: "
};

*/

const ArticleTemplate = {
  en: "Write an article that is informative and interesting.\n\"\"\"\n",
  es: "Escribe un artículo que sea informativo e interesante.\n\"\"\"\n",
  eo: "Skribu artikolon kiu estas informa kaj interesa.\n\"\"\"\n"
};



export { SearchTemplate, ArticleTemplate };
