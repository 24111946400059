import React from 'react';

import Logo from '../Logo/Logo';
import SearchBar from '../SearchBar/SearchBar';

function SearchHeader() {
  return (
    <header className="flex items-center content-center w-full px-4 py-2 border border-googray-light">
      <a href="/">
        <Logo className="text-3xl mr-2 sm:mr-8" />
      </a>
      <div className="flex-grow"></div>
      <SearchBar className="w-full sm:w-1/2 mx-4 sm:mx-8" />
    </header>


  );
}

export default SearchHeader;
