import React from 'react';

function Logo({ className = '' }) {
  return (
    <div className={ 'max-w-[150px] font-vollkorn ' + className }>
      <img src="https://uploads-ssl.webflow.com/62f1aea8150fef2a60f7f51b/62f1d3f3e9e3670fbd458b42_Krause_House_Logo_Holding_Shape_PR%20(2).png" alt="DAO logo" width="60" height="100"/>
    </div>

  );
}

export default Logo;
