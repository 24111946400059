import React, { useContext, useState } from 'react';

import { AppContext } from '../../contexts/AppContext/AppContext';

import Result from '../Result/Result';
import LoadingResult from '../LoadingResult/LoadingResult';

function ResultsList() {
  const { getArticleTemplate, searchTerm, searchResults, isLoadingResults } = useContext(AppContext);

  let [text, setText] = useState(getArticleTemplate());

  const resultsList = searchResults.map((result, index) => {

    if (result) {
      setText(getArticleTemplate() + result?.text);

      return (
        <Result
          title={ searchTerm }
          text={ text }
          key={ index }
        />
      );



    }



  });

  return (
    <div className="space-y-6">
      { resultsList.length > 0 &&
        resultsList
      }

      { (!resultsList.length || isLoadingResults) &&
        <LoadingResult />
      }
    </div>
  );
}

export default ResultsList;
