import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext/AppContext';

import { Grid, Card, Button } from "@nextui-org/react";
import {useMediaQuery} from '../utils/useMediaQuery.js'

import Logo from '../components/Logo/Logo';
import SearchBar from '../components/SearchBar/SearchBar';

import LanguageSelect from '../components/LanguageSelect/LanguageSelect';
import Footer from '../components/Footer/Footer';
//import Proposals from '../components/Proposals/Proposals';
//import ModalTabs from '../components/ModalTabs/ModalTabs';

function Home() {
  const {
    searchTerm,
    gooptSearch,
    setSearchTerm,
    imFeelingLucky,
  } = useContext(AppContext);



  const handleChange = (term) => {
    setSearchTerm(term);
    handleKeyDown(term)
  }


  const handleKeyDown = (term) => {
      imFeelingLucky(term);

  };



  const isMd = useMediaQuery(960);


  return (

 <div className="flex flex-col w-full h-screen">
    <header className="flex items-center content-center w-full px-6 py-2 border border-googray-light">
        <a href="/">
          <Logo className="text-3xl mr-14" />
        </a>

        <div className="flex justify-end w-full">
           <Button flat color="warning" auto>
            <a href="/governance">
              <a className="text-yellow">Governance</a>
            </a>
          </Button>
        </div>

      </header>




        <div className="flex flex-col items-center justify-center w-full h-full space-y-7">

        <p class="mt-8 sm:mt-6 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 text-2xl font-bold leading-tight text-gray-900 sm:text-3xl md:text-4xl lg:text-5xl xl:text-4xl">
    Your AI-assisted guide to exploring the world of Krause House
  </p>




          <SearchBar
            className="w-5/12 h-12"
            autoFocus={ true }
          />

          <Grid.Container gap={2}>

            <Grid xs={12} md={6}>
              <Card
                isPressable
                isHoverable
                variant="flat"
                onPress={() => handleChange("What is Krause House?")}
                onPressEnd={handleKeyDown}
                className="flex justify-center items-center h-full"

              >
                <Card.Body>
                  <a class="text-black font-semibold">What is Krause House?</a>
                </Card.Body>
              </Card>
            </Grid>

            <Grid xs={12} md={6}>
              <Card
                isPressable
                isHoverable
                variant="flat"
                onPress={() => handleChange("How can I submit a proposal?")}
                onPressEnd={handleKeyDown}
                className="flex justify-center items-center h-full"

              >
                <Card.Body>
                  <a class="text-black font-semibold">How can I submit a proposal?</a>
                </Card.Body>
              </Card>
            </Grid>

            <Grid xs={12} md={6}>
              <Card
                isPressable
                isHoverable
                variant="flat"
                onPress={() => handleChange("What are the membership requirements?")}
                onPressEnd={handleKeyDown}
                className="flex justify-center items-center h-full"

              >
                <Card.Body>
                  <a class="text-black font-semibold">What are the membership requirements?</a>
                </Card.Body>
              </Card>
            </Grid>

            <Grid xs={12} md={6}>
              <Card
                isPressable
                isHoverable
                variant="flat"
                onPress={() => handleChange("What is the goal of Krause house?")}
                onPressEnd={handleKeyDown}
                className="flex justify-center items-center h-full"


              >
                <Card.Body>
                  <a class="text-black font-semibold">What is the goal of Krause house?</a>
                </Card.Body>
              </Card>
            </Grid>

          </Grid.Container>






        </div>


        <div className="flex justify-between w-full px-3 py-2 text-md font-roboto text-googray-text">


          {/* <LanguageSelect /> */}
        </div>



        <Footer/>

      </div>


  );
}

export default Home;
