import axios from 'axios';

export function fetch_query(prompt, onData) {
  const url = `https://gpt-backend-app.herokuapp.com/krausehouse/search?prompt=${prompt}`;

  const eventSource = new EventSource(url);

  eventSource.onmessage = function (event) {
    const chunk = event.data;

    // Check if the chunk is the end of the stream
    if (chunk === 'end') {
      eventSource.close(); // Close the streaming connection
      return;
    }

    // Pass the chunk to the callback function
    onData(chunk);
  };

  eventSource.onerror = function (error) {
    // Handle the error
    console.error('Error fetching data:', error);
    eventSource.close(); // Close the streaming connection
  };
}








export async function validate_proposal(proposal) {
  const url = '';
  try {
    const response = await axios.post(url, proposal, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}



export async function validate_grant(content) {
  const url = ``;
  try {
    const response = await axios.post(url, content, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
