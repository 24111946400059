import React, { useContext, useState } from 'react';

import { AppContext } from '../contexts/AppContext/AppContext';

import SearchHeader from '../components/SearchHeader/SearchHeader';
import ResultsList from '../components/ResultsList/ResultsList';
import Result from '../components/Result/Result';
import Button from '../components/Button/Button';

function Results() {
  const { searchTerm, isLoadingResults, getMoreResults, searchResults } = useContext(AppContext);




return (
  <div className="w-full h-screen">
    <SearchHeader />

    <div className="pb-6 mt-2 text-left pl-44">
      <div className="mb-6 text-sm text-googray">
        GPT-Powered Answer
      </div>

      <ResultsList />

      {/*

      { !isLoadingResults &&
        <Button
          onClick={ getMoreResults }
          className="mt-6"
        >
          Load More
        </Button>
      }

      */}
    </div>
  </div>
);
}

export default Results;
