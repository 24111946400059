import React from 'react';

function Footer({ className = '' }) {
  return (
    <footer className="footer">
      <div className="container flex flex-col justify-between py-4 mx-auto space-y-8  items-center justify-center">
        <div className="flex items-center ">
          <span className="flex text-xs font-light mr-2">Powered by</span>
          <a rel="noopener noreferrer" href="https://daohub.xyz" className="flex items-center justify-center space-x-1 " target="_blank">
            <div className="flex items-center justify-center w-9 h-9 rounded-full bg-rose-600" href="https://daohub.xyz" target="_blank">
              <img src="https://www.daohub.xyz/img/favicon.png" alt="DAO logo" />
            </div>
            <span className="self-center text-2md font-semibold">DAOhub</span>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
