import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../contexts/AppContext/AppContext';
import { Card } from "@nextui-org/react";
import LoadingArticle from '../components/LoadingArticle/LoadingArticle';
import ReadMore from '../components/ReadMore/ReadMore';
import SearchHeader from '../components/SearchHeader/SearchHeader';
import Footer from '../components/Footer/Footer';

function Site() {
  const {
    searchTerm,
    isLoadingResults,
    isLoadingArticle,
    imFeelingLucky,
    searchResults,
    getArticleTemplate
  } = useContext(AppContext);

  const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));
  const [title, setTitle] = useState(searchTerm);
  const [initialText, setInitialText] = useState(queryParams.get('text'));
  const [textChunks, setTextChunks] = useState([]);
  const [visibleChunks, setVisibleChunks] = useState(0);

  useEffect(() => {
    setQueryParams(new URLSearchParams(window.location.search));
  }, [window.location.search]);

  useEffect(() => {
    initArticle();
  }, [queryParams]);




  const initArticle = async () => {
    if (title && initialText?.length < 800) {
      const streamedResponse = await imFeelingLucky(searchTerm);

      const chunks = [];
      const eventSource = new EventSource(streamedResponse);

      eventSource.onmessage = function (event) {
        const chunk = event.data;
        chunks.push(chunk);
        handleDataChunk(chunk);
      };
    }
  };

  const handleDataChunk = (chunk) => {
    const formattedChunk = chunk.trim(); // Remove leading/trailing whitespace
    setTextChunks((prevChunks) => [...prevChunks, formattedChunk]);
  };



  return (
    <div className="w-full h-screen">
      <SearchHeader />
      <div className="flex justify-center py-6 sm:py-12">
        <div className="w-full sm:w-10/12 md:w-8/12 lg:w-6/12 text-left px-4 sm:px-0">
          <h1 className="mb-6 text-lg sm:text-xl font-montserrat">
            {title}
          </h1>
          <Card isHoverable={true} variant="flat" css={{ mw: "800px", p: '15px' }}>
            <Card.Body>
            <p className="text-lg font-light tracking-normal sm:text-lg results-container">
              {searchResults.length > 0 && searchResults}
            </p>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Site;
