import axios from "axios";

export const GetDaoProposals = async () => {
  const PROPOSALS_QUERY = `
  query Proposals {
    proposals (
      first: 20,
      skip: 0,
      where: {
        space_in:["krausehouse.eth"],
      },
      orderBy: "end",
      orderDirection: desc
    ) {
      id
      ipfs
      title
      type
      body
      choices
      discussion
      start
      end
      snapshot
      state
      author
      space {
        id
        name
      }
      votes
      scores_total
      scores_updated
      link
      scores
    }
  }`;
  const response = axios({
    url: "https://hub.snapshot.org/graphql",
    method: "post",
    data: {
      query: PROPOSALS_QUERY,
    },
  });

  return response;
};



export async function summarize_proposal(url) {
  try {
    const response = await fetch(
      `https://gpt-backend-app.herokuapp.com/krausehouse/proposal_summary?proposal_id=${url}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
